import { ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import type { VotingSession, VoterData, VoterId, LegacyVotingSession } from "./schemas/voting-session";
import { authenticatedPut } from "../util/utils";
import { clientStateKey } from "./client-state";
import { ClientState } from "./schemas/client-state";

type OldAndNew = LegacyVotingSession | VotingSession;

export const VotingLegacyPrefix = "voting-";
export const VotingSessionPrefix = "voting/s/";
export const VotingUserDataPrefix = "voting/u/";
export const VotingThumbnailsPrefix = "voting/t/";

export function sessionId({ start }: { start: number }): number {
  return start;
}

function sessionKey(sessionId: number | string): string {
  return VotingSessionPrefix + sessionId;
}

export function prefixForUserDataInSession(sessionId: number | string) {
  return VotingUserDataPrefix + sessionId;
}

export function userDataKey(sessionId: unknown, userId: unknown) {
  return `${VotingUserDataPrefix}${sessionId}/${userId}`;
}

export function thumbnailsKey(sessionId: number | string) {
  return VotingThumbnailsPrefix + sessionId;
}

function isOldSession(session: OldAndNew | null): session is LegacyVotingSession {
  return session != null && "maxVotesPerChoice" in session;
}

function notHidden({ hidden }: OldAndNew) {
  return !hidden;
}

function isRunningInMoment(timestamp: number, session: OldAndNew) {
  return timestamp > session.start && timestamp < session.start + (session.duration || 300) * 1000;
}

function convertLegacyToCurrent(session: LegacyVotingSession): VotingSession {
  return {
    ownerId: session.ownerId,
    title: session.title,
    start: session.start,
    duration: session.duration ?? 5 * 60,
    numVotes: session.maxVotesPerUser,
    anonymous: false, // legacy sessions were never anonymous, though they supported it
    maxVotesOnObject: Number.MAX_SAFE_INTEGER, // legacy sessions had this option, but we never used it
    hidden: session.hidden,
  } as VotingSession;
}

async function findActiveVotingSession(tx: ReadTransaction, prefix: string) {
  const sessions = (await tx.scan({ prefix }).values().toArray()) as OldAndNew[];
  const now = Date.now();
  return sessions.find((session) => notHidden(session) && isRunningInMoment(now, session));
}

export async function getActiveVotingSession(tx: ReadTransaction): Promise<VotingSession | undefined> {
  const active =
    (await findActiveVotingSession(tx, VotingSessionPrefix)) || (await findActiveVotingSession(tx, VotingLegacyPrefix));
  // this code assumes there's a single active session
  // To validate or support multiple active:
  // replace find with filter, then return all or the one with latest start time
  if (active && isOldSession(active)) {
    return convertLegacyToCurrent(active);
  }
  return active;
}

export async function getAllVotingSessions(tx: ReadTransaction): Promise<OldAndNew[]> {
  const legacy = (await tx.scan({ prefix: VotingLegacyPrefix }).values().toArray()) as LegacyVotingSession[];
  const newStyle = (await tx.scan({ prefix: VotingSessionPrefix }).values().toArray()) as VotingSession[];
  return [...legacy, ...newStyle].filter(notHidden);
}

export async function startVotingSession(tx: WriteTransaction, session: Required<VotingSession>): Promise<void> {
  await authenticatedPut(tx, sessionKey(sessionId(session)), session);
}

function computeDurationToEnd(start: number, endIn: number, originalDuration = 5 * 60): number {
  const origEndTime = start + originalDuration * 1000;
  if (endIn < origEndTime) {
    return (endIn - start) / 1000; // the new duration
  }
  return originalDuration; // the old duration, which is still valid
}

export async function endVotingSession(
  tx: WriteTransaction,
  args: { session: Required<VotingSession>; endTimestamp: number }
): Promise<void> {
  const modernKey = sessionKey(sessionId(args.session));
  const s = (await tx.get(modernKey)) as any;
  if (s) {
    return authenticatedPut(tx, modernKey, {
      ...s,
      duration: computeDurationToEnd(s.start, args.endTimestamp, s.duration),
    });
  }

  const legacyKey = VotingLegacyPrefix + args.session.start;
  const legacyS = (await tx.get(legacyKey)) as any;
  if (legacyS) {
    return authenticatedPut(tx, legacyKey, {
      ...legacyS,
      duration: computeDurationToEnd(legacyS.start, args.endTimestamp, legacyS.duration),
    });
  }
  console.warn("asked to end a session that doesn't exist");
}

export async function hideVotingSession(
  tx: WriteTransaction,
  args: { sessionId: number; hide: boolean }
): Promise<void> {
  const modernKey = sessionKey(args.sessionId);
  const s = (await tx.get(modernKey)) as any;
  if (s) {
    return authenticatedPut(tx, modernKey, { ...s, hidden: args.hide });
  }

  const legacyKey = VotingLegacyPrefix + args.sessionId;
  const legacyS = (await tx.get(legacyKey)) as any;
  if (legacyS) {
    return authenticatedPut(tx, legacyKey, { ...legacyS, hidden: args.hide });
  }
  console.warn("asked to delete a session that doesn't exist");
}

export async function renameVotingSession(
  tx: WriteTransaction,
  args: { sessionId: number; newName: string }
): Promise<void> {
  const modernKey = sessionKey(args.sessionId);
  const s = (await tx.get(modernKey)) as any;
  if (s) {
    return authenticatedPut(tx, modernKey, { ...s, title: args.newName });
  }

  const legacyKey = VotingLegacyPrefix + args.sessionId;
  const legacyS = (await tx.get(legacyKey)) as any;
  if (legacyS) {
    return authenticatedPut(tx, legacyKey, { ...legacyS, title: args.newName });
  }
  console.warn("asked to rename a session that doesn't exist");
}

async function getProfileOfGuestVoter(tx: ReadTransaction): Promise<VoterId> {
  const clientState = await tx.get(clientStateKey(tx.clientID));
  if (clientState) {
    const { name, avatar } = (clientState as ClientState).userInfo;
    return { type: "anonymous", id: tx.clientID, name, avatar: avatar || "🙂" };
  } else {
    return { type: "anonymous", id: tx.clientID, name: "unknown", avatar: "🙂" };
  }
}

export async function vote(
  tx: WriteTransaction,
  args: { sessionId: number; userId?: string; elementId: string; vote: boolean }
): Promise<void> {
  // I'm skipping any validations about the session existing and being active
  const id = args.userId ?? tx.clientID;
  const key = userDataKey(args.sessionId, id);
  let userVotes: VoterData = (await tx.get(key)) as VoterData;
  if (!userVotes) {
    const userId: VoterId = args.userId ? { type: "known", id: args.userId } : await getProfileOfGuestVoter(tx);
    // The first vote has to be a 'yes' vote, and I assume it here but don't check
    userVotes = { id: userId, votes: { [args.elementId]: 1 } };
  } else {
    const currentVotesOnObject = userVotes.votes[args.elementId] || 0;
    const delta = args.vote ? 1 : -1;
    const newVotes = Math.max(0, currentVotesOnObject + delta);
    userVotes = {
      ...userVotes,
      votes: {
        ...userVotes.votes,
        [args.elementId]: newVotes,
      },
    };
  }
  return authenticatedPut(tx, key, userVotes);
}

export async function setVotingRecord(
  tx: WriteTransaction,
  args: { userId?: string; sessionId: number; votes: Record<string, number> }
) {
  // I'm skipping any validations about the session existing and being active
  const id = args.userId || tx.clientID;
  const key = userDataKey(args.sessionId, id);
  const userId: VoterId = args.userId ? { type: "known", id: args.userId } : await getProfileOfGuestVoter(tx);
  return authenticatedPut(tx, key, { id: userId, votes: args.votes });
}

export async function setThumbnails(tx: WriteTransaction, args: { sessionId: number; urls: Record<string, string> }) {
  const key = VotingThumbnailsPrefix + args.sessionId;
  return authenticatedPut(tx, key, args.urls);
}
